<template>
  <div>
	  <div v-loading.fullscreen="fullscreenLoading" style="text-align:center;">
	  	<img style="margin:100px auto;" :src="payGateWay" />
	  </div>
  </div>
</template>

<script>
  export default{
    name:'orderAlipay',
    data(){
      return{
        fullscreenLoading:false,
        payGateWay: ""
      }
    },
    methods:{
      loadingPayData() {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: '#fff'
        });
        setTimeout(() => {
          loading.close();
        },3000);
      }
    },
    mounted () {
      this.loadingPayData();
      this.payGateWay = this.$route.query.payData;
      this.$nextTick(()=> {
        document.forms[0].submit();
      })
    },
  }
</script>

<style scoped lang="scss">

</style>
